<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>

    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/en-联系我们banner.png" class="images" />
      </el-carousel-item>
    </el-carousel>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content_1">
        <h2 class="H2">Contact Us</h2>
        <p>Chongqing</p>
        <p style="text-indent: 2em">Shine Optics Technology Company Limited</p>
        <p>Add:</p>
        <p style="text-indent: 2em; width: 500px">
          No.13, Xihu Road, Tongqiao Street, Dazu District, Chongqing City, China.
        </p>
        <br />
        <p>Jiangxi</p>
        <p style="text-indent: 2em">Jiangxi Shinetech Precision Optical Co., Ltd.</p>
        <p>Add:</p>
        <p style="text-indent: 2em; width: 500px">
          Shinetech Precision Optics, Chengdong Industrial area, Fenyi County, Xinyu city,
          Jiangxi Province.
        </p>
        <br />
        <p>Shenzhen</p>
        <p style="text-indent: 2em">Shenzhen Shine Optics Technology Company Limited</p>
        <p>Add:</p>
        <p style="text-indent: 2em; width: 500px">
          No.2505, Jia'anda Building, Huafan Road, Longhua Distract, Shenzhen City,
          Guangdong Province, China.
        </p>
        <br />
        <p>Tel: 023-85226001</p>
        <p>FAX: 023-85226001</p>
      </div>
      <!-- <div class="content_2">
        <h2 class="H2">商业合作伙伴</h2>
        <div class="logo">
          <a :href="item.hrefS" target="_blank" v-for="item in cooperation" :key="item.id">
            <img :src="item.src" />
          </a>

        </div>
      </div> -->
      <div class="content_3">
        <div class="home">
          <h2 class="H3">Global Branch Organization</h2>
        </div>
      </div>
      <div class="images">
        <div class="p_01">
          <p v-for="item in siteList" :key="item.id">{{ item.title }}</p>
          <!-- <p class="p_01_1" style="height: 20px;" >台湾盛泰光电有限公司办事处</p> -->
        </div>
        <img src="@/assets/contact/en-地图.png" alt="" />
        <!-- <img src="@/assets/网站用图/线条.png" class="image_1">
        <img src="@/assets/网站用图/分布图.png" class="image_2"> -->
      </div>
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/en-联系我们banner.png" style="width: 100%" />
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <h2>Contact Us</h2>
    <div class="info1">
      <p>Chongqing</p>
      <p style="text-indent: 2em">Shine Optics Technology Company Limited</p>
      <p>Add:</p>
      <p style="text-indent: 2em">
        No.13, Xihu Road, Tongqiao Street, Dazu District, Chongqing City, China.
      </p>
      <br />
      <p>Jiangxi</p>
      <p style="text-indent: 2em">Jiangxi Shinetech Precision Optical Co., Ltd.</p>
      <p>Add:</p>
      <p style="text-indent: 2em">
        Shinetech Precision Optics, Chengdong Industrial area, Fenyi County, Xinyu city,
        Jiangxi Province.
      </p>
      <br />
      <p>Shenzhen</p>
      <p style="text-indent: 2em">Shenzhen Shine Optics Technology Company Limited</p>
      <p>Add:</p>
      <p style="text-indent: 2em">
        No.2505, Jia'anda Building, Huafan Road, Longhua Distract, Shenzhen City,
        Guangdong Province, China.
      </p>
      <br />
      <p>Tel: 023-85226001</p>
      <p>FAX: 023-85226001</p>
    </div>
    <h2>Global Branch Organization</h2>
    <div class="info1">
      <p v-for="item in siteList" :key="item.id">{{ item.title }}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs, onMounted } from "vue";

export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const state = reactive({
      dataList: [
        { id: 1, title: "企业业务联系方式" },
        { id: 2, title: "商业合作伙伴信息" },
        { id: 3, title: "国内分支机构" },
      ],
      siteList: [
        { id: 1, title: "Shine Optics Technology Company Limited" },
        { id: 2, title: "Jiangxi Shinetech Precision Optical Co., Ltd." },
        { id: 3, title: "Shenzhen Shine Optics Technology Company Limited" },
        { id: 4, title: "Shinetech Singapore Office" },
        { id: 5, title: "Shinetech India Production Manufacturing Plant" },
        { id: 6, title: "Shinetech Southeast Asia office" },
        { id: 7, title: "Shinetech Canifornia office" },
      ],
    });
    onMounted(() => {});
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
p {
  font-weight: 400;
}

.p_01_1 {
  margin-top: 25px;
}

.logo {
  // display: flex;
  flex-wrap: wrap;

  img:nth-child(10) {
    padding-bottom: 25px;
  }

  img:nth-child(11) {
    padding-bottom: 30px;
  }

  img:nth-child(15) {
    padding-bottom: 3px;
  }

  img {
    margin: 0 auto;
    flex: 1;
    cursor: pointer;
    max-width: 20%;
    min-width: 20%;
    padding: 15px 63px;
    box-sizing: border-box;
  }
}

.images {
  // width: 100%;
  display: flex;
  position: relative;
  img {
    width: 70%;
    position: absolute;
    right: -150px;
    top: -31.5rem;
  }
  .image_1 {
    width: 100%;
    height: 200px;
    margin-top: 28px;
    margin-left: 30px;
  }

  .image_2 {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin-top: -46px;
  }

  .p_01 {
    width: 30%;
    min-width: 208px;
    margin-left: 2rem;
    p {
      margin: 0;
      padding: 0;
      margin: 20px 0;
      // width: 240px;
      height: auto;
      overflow: hidden;
    }
  }
}

.content_3 {
  position: relative;
  height: 70px;

  .home,
  .foreign {
    position: absolute;
  }

  .foreign {
    left: 270px;
  }
}

.H2 {
  width: 100%;
  position: relative;
  font-size: 1.7rem;
}

.H2::before {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  bottom: -3px;
  left: 7%;
  transform: translateX(-50%);
  // top:55px;
  min-width: 35px;
  max-width: 35px;
}

.H3 {
  font-size: 1.7rem;
}

.H3::before {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #c7000b;
  position: absolute;
  bottom: 0.9rem;
  left: 47%;
  transform: translateX(-50%);
  // top:55px;
  min-width: 35px;
  max-width: 35px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  .content {
    width: 70%;
    margin: 50px auto;
    max-width: 1920px;

    .content_1 {
      position: relative;
      // display: flex;
      flex-wrap: wrap;
      p {
        margin-left: 2rem;
      }
      .phone {
        flex: 1;
        max-width: 20%;
        min-width: 20%;
      }

      .work {
        max-width: 70%;
        min-width: 70%;
        flex: 4;
      }

      .fax {
        flex: 1;
        max-width: 20%;
        min-width: 20%;
      }

      .plant {
        max-width: 70%;
        min-width: 70%;
        flex: 4;
      }
    }

    .content_2 {
      height: auto;
      position: relative;
    }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    h2 {
      text-align: center;
      font-size: 80px;
    }
    h2::before {
      content: "";
      width: 30%;
      height: 8px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 100px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100px;
      max-width: 100px;
    }
    .info1 {
      font-size: 40px;
      padding: 0px 70px;
      box-sizing: border-box;
    }
  }
}
</style>
